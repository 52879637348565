import React from "react";
import "./style.scss";

const Home = () => {
  return (
    <main className="bb-home">
      <h1>bubuka.ca</h1>
    </main>
  );
}

export default Home;
